import React from "react";

const options = [
    {
        name: 'OCI8.LIBS',
        scope: 'api',
        description: `Forces SQLAPI++ library to use specified OCI library.`,
        valid:
            <>
                Any valid OCI library name list. Names separated by ';' on Windows or ':' on other operating
                systems
            </>,
        defaultValue:
            <ul>
                <li>Windows - {' '}<code>"oci.dll"</code></li>
                <li>Linux - {' '}<code>"libclntsh.so"</code></li>
            </ul>,
        special:
            <>
                <code>"STATIC"</code>, forces using the linked OCI functions when
                the library is compiled with <code className="text-reset">SA_STATIC_ORACLE</code> build option.
            </>
    },
    {
        name: 'UseUCS2',
        scope: 'api',
        description: `[OCI >= 9; UNICODE only] UNICODE version of the SQLAPI++ uses UCS2 client character set for
                      Oracle OCI >= 9. This option allows to use pre-configured client character set
                      with the UNICODE SQLAPI++ version.`,
        valid:
            <>
                <code>"No"</code>, {' '}<code>"False"</code>{' '} or {' '}<code>"0"</code>
            </>
    },
    {
        name: 'NLS_CHAR',
        scope: 'api',
        description:
            <>
                [OCI >= 9; non-UNICODE]
                This option allows to specify client character set for entire connection with
                non-UNICODE SQLAPI++ version.
            </>,
        valid:
            <>
                String containing Oracle character set name (<code>"US7ASCII"</code>,
                {' '}<code>"CL8MSWIN1251"</code>, {' '}<code>"SF7DEC"</code>, etc.) or its numeric code
                (<code>"1"</code>, {' '}<code>"171"</code>, {' '}<code>"21"</code>, etc.).
                See Oracle documentation for more details.
            </>,
        defaultValue:
            <>By default SQLAPI++ doesn't change Oracle settings for this option.</>
    },
    {
        name: 'ConnectAs',
        scope: 'connection',
        description: `Forces SQLAPI++ to connect with SYSDBA or SYSOPER privileges.`,
        valid: <><code>"SYSDBA"</code>{' '} or <code>"SYSOPER"</code></>
    },
    {
        name: 'UseTimeStamp',
        scope: 'connection',
        description: `This option allows to disable the usage of the OCIDateTime. Useful when OCI version
                      8 and higher is used with an old Oracle server versions that don't support TimeStamp data type.`,
        valid:
            <>
                <code>"No"</code>, {' '}<code>"False"</code>{' '} or {' '}<code>"0"</code>
            </>
    },
    {
        name: 'UseSessionPool',
        scope: 'connection',
        description: `Forces SQLAPI++ to use Oracle session pool feature.`,
        valid: 'One-based index of the used session pool',
        defaultValue:
            <>
                <code>"0"</code>{' '} (no session pool is used)
            </>
    },
    {
        name: 'UseSessionPool_Min',
        scope: 'connection',
        description: `Specifies min size of the session pool.`,
        valid: 'String containing the min size of the pool',
        defaultValue: <><code>"10"</code></>
    },
    {
        name: 'UseSessionPool_Max',
        scope: 'connection',
        description: `Specifies max size of the session pool.`,
        valid: 'String containing the max size of the pool',
        defaultValue: <><code>"100"</code></>
    },
    {
        name: 'UseSessionPool_Incr',
        scope: 'connection',
        description: `Specifies the session pool increment value.`,
        valid: 'String containing the pool increment value',
        defaultValue: <><code>"10"</code></>
    },
    {
        name: 'UseSessionPool_Timeout',
        scope: 'connection',
        description: `Specifies the session pool OCI_ATTR_SPOOL_TIMEOUT attribute value.`,
        valid: 'String containing the timeout value (in seconds)'
    },
    {
        name: 'UseConnectionPool',
        scope: 'connection',
        description: `Forces SQLAPI++ to use Oracle connection pool feature.`,
        valid: 'One-based index of the used connection pool',
        defaultValue:
            <>
                <code>"0"</code>{' '} (no connection pool is used)
            </>
    },
    {
        name: 'UseConnectionPool_MinConn',
        scope: 'connection',
        description: `Specifies min size of the connection pool.`,
        valid: 'String containing the min size of the pool',
        defaultValue: <><code>"10"</code></>
    },
    {
        name: 'UseConnectionPool_MaxConn',
        scope: 'connection',
        description: `Specifies max size of the connection pool.`,
        valid: 'String containing the max size of the pool',
        defaultValue: <><code>"100"</code></>
    },
    {
        name: 'UseConnectionPool_ConnIncr',
        scope: 'connection',
        description: `Specifies connection pool increment value.`,
        valid: 'String containing the pool increment value',
        defaultValue: <><code>"10"</code></>
    },
    {
        name: 'APPNAME',
        scope: 'connection',
        description: `OCI_ATTR_CLIENT_IDENTIFIER is used to identify client connection.`,
        valid: 'client application name string',
        defaultValue: 'none'
    },
    {
        name: 'OCI_ATTR_RECEIVE_TIMEOUT, OCI_ATTR_SEND_TIMEOUT',
        scope: 'connection',
        description: `Undocumented OCI attributes allow to specify connection socket timeouts.`,
        valid: 'the string representing timeout value (in milliseconds)',
        defaultValue: 'none'
    },
    {
        name: 'OCI_ATTR_DEFAULT_LOBPREFETCH_SIZE',
        scope: 'connection',
        description: `The value of this attribute indicates the default prefetch data size for a LOB locator. This attribute value enables prefetching for all the LOB locators fetched in the session. The default value for this attribute is zero (no prefetch of LOB data).`,
        valid: 'the string representing the prefetch data size value (in bytes)',
        defaultValue: 'none'
    },
    {
        name: 'Overload',
        scope: 'command',
        description: `It is used when SQLAPI++ tries to detect package method
                      parameters. When SQLAPI++ enumerates the package methods it uses the
                      method with the "Overload" number to describe the parameters.`,
        valid: 'String containing an integer',
        defaultValue: <><code>"1"</code></>
    },
    {
        name: 'PreFetchRows',
        scope: 'command',
        description: `Forces SQLAPI++ library to fetch rows in bulk, rather than retrieving records one by one.`,
        valid: 'String containing number of rows in the fetch buffer',
        defaultValue: <code>"1"</code>
    },
    {
        name: 'UseLongPrefetchCache',
        scope: 'command',
        description: `Forces SQLAPI++ library to fetch rows in bulk event the result set contains LONG fields.`,
        valid: <><code>"True"</code>, {' '}<code>"1"</code></>,
        defaultValue: <code>"False"</code>
    },
    {
        name: 'LongPrefetchCachePieceSize',
        scope: 'command',
        description: `Sets the default piece size for the prefetched LONG fields.`,
        valid: 'the string representing the piece size value (in bytes)',
        defaultValue: '2000'
    },
    {
        name: 'OCI_ATTR_PREFETCH_ROWS, OCI_ATTR_PREFETCH_MEMORY',
        scope: 'command',
        description: `This options sets the corresponding OCI statement attribute values.`,
        valid: 'String representing an integer value. See Oracle documentation for more details.',
        defaultValue: 'By default SQLAPI++ doesn\'t change Oracle settings for these options.'
    },
    {
        name: 'UseDynamicCursor, Scrollable',
        scope: 'command',
        description: `Forces SQLAPI++ to use scrollable readonly cursor. Requires OCIStmtFetch2 API function.`,
        valid: <><code>"True"</code>, {' '}<code>"1"</code></>,
        defaultValue: <code>"False"</code>
    },
    {
        name: 'OCI_ATTR_CHARSET_ID',
        scope: ['parameter', 'field'],
        description: `This option sets character set ID of the parameter or the field.`,
        valid:
            <>
                String containing Oracle character set name (<code>"US7ASCII"</code>,
                {' '}<code>"CL8MSWIN1251"</code>, {' '}<code>"SF7DEC"</code>, etc.) or its numeric code
                (<code>"1"</code>, {' '}<code>"171"</code>, {' '}<code>"21"</code>, etc.).
                See Oracle documentation for more details.
            </>,
        defaultValue:
            <>
                By default SQLAPI++ doesn't change Oracle settings for this option.
            </>
    },
    {
        name: 'OCI_ATTR_CHARSET_FORM',
        scope: ['parameter', 'field'],
        description: `This option sets the character set form of the parameter or the field.`,
        valid:
            <>
                <code>"SQLCS_IMPLICIT"</code>, {' '}<code>"SQLCS_NCHAR"</code>. See Oracle documentation for more
                details.
            </>,
        defaultValue: 'By default SQLAPI++ doesn\'t change Oracle settings for this option.'
    },
    {
        name: 'OCI_ATTR_LOBPREFETCH_SIZE',
        scope: 'field',
        description: `Like OCI_ATTR_DEFAULT_LOBPREFETCH_SIZE but for the speciafied command.`,
        valid: 'the string representing the prefetch data size value (in bytes)',
        defaultValue: 'none'
    },
    {
        name: 'OCI_ATTR_LOBPREFETCH_LENGTH',
        scope: 'field',
        description: `The attribute to the prefetch LOB length and chunk size. This is mandatory to be set to TRUE for the attribute OCI_ATTR_LOBPREFETCH_SIZE.`,
        valid: <><code>"True"</code>, {' '}<code>"1"</code></>,
        defaultValue: 'none'
    }
];

export {
    options
};
