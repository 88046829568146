import React from "react";

import ServerGuide, {
    GeneralCancelCommandNotes,
    GeneralUsingNativeApiNotes,
    GeneralNativeConnectionHandles,
    GeneralNativeCommandHandles, ImmediateOutputParameters
} from "../../components/server-guide";
import {CodeSnippet} from "../../components/snippet";
import {ApiLink} from "../../components/link";
import {options} from "../../data/oracle";
import {ServerLink} from "../../components/servers";
import {Callout} from "../../components/alert";

const ConnectApiNotes = () => (
    <Callout heading="Minimum Version">
        SQLAPI++ library requires Oracle OCI version 8 or higher.
    </Callout>
);
const sDBString = () => (
    <p>
        Any valid Oracle connection string. For example - a database alias name as it
        is specified in TNSNAMES.ORA file. Additional information about Oracle connection
        string available in
        {' '}
        <a href="http://www.oracle.com/technetwork/database/features/oci/ic-faq-094177.html">
            Oracle Instant Client FAQ
        </a>.
    </p>
);

const UserID = () => (
    <>
        <p>
            A string containing a user name to use when establishing the connection.
            If {' '}<code className="text-reset">sUserID</code>{' '} parameter is empty, SQLAPI++ library requests
            Oracle external authentication.
        </p>
    </>
);

const OracleLobNotes = () => (
    <>
        <p>
            Binding Lob (CLob, BLob) data when working with
            {' '}<ServerLink>Oracle</ServerLink>{' '} server
            has a specific requirement &ndash; it is necessary for a name of bind
            variable to be the same as the column name it is associated with:
        </p>
        <CodeSnippet language="sql">
            UPDATE TEST SET FBLOB = :fblob WHERE FKEY = 'KEY'
        </CodeSnippet>

        <p>
            Therefore, it's impossible to bind Lob (CLob, BLob) data in Oracle database by position.
        </p>
    </>
);

const ErrorNotes = () => (
    <p>
        In Oracle server {' '}<ApiLink>SAException::ErrPos</ApiLink>{' '} method returns parse error offset.
    </p>
);

const Page = () => (
    <ServerGuide server="Oracle"
                 connectApiNotes={ConnectApiNotes}
                 sDBString={sDBString}
                 sUserID={UserID}
                 lobNotes={OracleLobNotes}
                 outputParamNotes={() => <ImmediateOutputParameters server="Oracle"/>}
                 cancelCommandNotes={() => <GeneralCancelCommandNotes server="Oracle"
                                                                      nativeCommand="OCIBreak"/>}
                 options={options}
                 usingNativeApiNotes={() => <GeneralUsingNativeApiNotes server="Oracle"
                                                                        cppHeader="oraAPI.h"
                                                                        apiClass="oraAPI"/>}
                 nativeConnectionHandles={() => <GeneralNativeConnectionHandles server="Oracle"
                                                                                handleClass="oraConnectionHandles"
                                                                                cppHeader="oraAPI.h"/>}
                 nativeCommandHandles={() => <GeneralNativeCommandHandles server="Oracle"
                                                                          handleClass="oraCommandHandles"
                                                                          cppHeader="oraAPI.h"/>}
                 errorNotes={ErrorNotes}
    />
);

export default Page;
